import AOS from "aos";
import "./form.js";
import { removeBtnDisable } from "./form.js";
import "./google-map.js";
import "./header-space.js";
import "./tooltip.js";

AOS.init();



let youSave = document.getElementById("you-save");
removeBtnDisable(youSave);

window.addEventListener("DOMContentLoaded", (event) => {});

window.addEventListener("resize", (event) => {});

window.addEventListener("scroll", (event) => {});
